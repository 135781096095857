<template>
  <label v-bind:for="component_id" v-if="component_meta.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <span class="tip" v-if="component_meta.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <div class="sider-component-border">
    <label>桌面：</label>
    <input type="text" v-bind:id="component_id + '_desktop'" v-bind:name="component_meta.variable + '_desktop'"
      v-bind:value="component_value.desktop">


    <label>平板：</label>
    <input type="text" v-bind:id="component_id + '_pad'" v-bind:name="component_meta.variable + '_pad'"
      v-bind:value="component_value.pad">

    <label>手機：</label>
    <input type="text" v-bind:id="component_id + '_mobile'" v-bind:name="component_meta.variable + '_mobile'"
      v-bind:value="component_value.mobile">
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  props: ['meta', 'value', 'message'],
  emits: ['input'],
  data() {

    let width_pairs = this.meta.default.split(',');
    let localValue = this.value;
    if (typeof localValue !== 'object') {
      localValue = { 'desktop': '', 'pad': '', 'mobile': '' };
    }
    
    localValue.desktop = (localValue.desktop == undefined || localValue.desktop == '' || localValue.desktop == 0) ? width_pairs[0] : localValue.desktop;
    localValue.pad = (localValue.pad == undefined || localValue.pad == '' || localValue.pad == 0) ? width_pairs[1] : localValue.pad;
    localValue.mobile = (localValue.mobile == undefined || localValue.mobile == '' || localValue.mobile == 0) ? width_pairs[0] : localValue.mobile;


    return {
      component_id: 'colume-width-' + Math.random().toString().substring(2),
      component_value : localValue,
      component_meta  : this.meta
    }
  },
  mounted() {
    let $this = this;
    $('#' + this.component_id + '_desktop').ionRangeSlider({
      min: 1,
      max: 12,
      type: 'single',
      step: 1,
      postfix: ' 級',
      grid_snap: true,
      prettify: false,
      hasGrid: true,
      onChange: function (obj) {
        $this.component_value.desktop = obj.from;
        $this.update_input_value();
      }
    });
    $('#' + this.component_id + '_pad').ionRangeSlider({
      min: 1,
      max: 12,
      type: 'single',
      step: 1,
      postfix: ' 級',
      prettify: false,
      hasGrid: true,
      onChange: function (obj) {
        $this.component_value.pad = obj.from;
        $this.update_input_value();
      }
    })
    $('#' + this.component_id + '_mobile').ionRangeSlider({
      min: 1,
      max: 12,
      type: 'single',
      step: 1,
      postfix: ' 級',
      prettify: false,
      hasGrid: true,
      onChange: function (obj) {
        $this.component_value.mobile = obj.from;
        $this.update_input_value();
      }
    })
  },
  methods: {
    update_input_value: function ($event) {
      $event = {};
      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style>
span.tip {
  color: green;
}

/* 設定滑塊的背景和選定範圍的顏色 */
.irs {
  background-color: #f5f5f5;
}

.irs .irs-bar {
  background-color: #007bff;
}

/* 設定滑塊的標籤和按鈕的顏色 */
.irs .irs-handle {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.irs .irs-max {
  color: #007bff;
}

.irs .irs-from,
.irs .irs-to {
  color: #4CAF50;
}

div.sider-component-border {
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 5px;
}

div.sider-component-border label:not(:first-child) {
  margin-top: 30px;
}
</style>